@import 'scss/init';

.section--community {
  margin-top: 150px;
  margin-bottom: 200px;

  .modal-window & {
    margin-top: 30px;
    margin-bottom: 0;

    @include breakpoint(until-portrait) {
      margin-top: 40px;
    }
  }

  .community--icon {
    width: 80px;
    height: auto;
    display: block;
    margin: 0 auto;
    transform: translate(0, 0);
    transition: all 1.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    .community--icon { transform: translate(15px, -10px); }
  }

  .title-lines {
    background: none;
    margin-top: 0;
    padding-top: 10px;
    margin-bottom: 25px;
    padding-bottom: 0;

    .modal-window & {
      display: none;
    }
  }

  .intro-p {
    max-width: 500px;
    text-align: center;
    margin: 0 auto 40px;
  }
}
