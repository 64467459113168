@import 'scss/init';

.pg-witrm {
  overflow-x: hidden;

  #main-content {
    background-size: 100% 550px;
  }

  .section--marketplace-cta {
    margin-top: 200px;

    @include breakpoint(until-portrait) {
      margin-top: 150px;
    }

    .title-lines {
      margin-bottom: 20px;
    }
  }

  a {
    color: $green;
  }
}

.witrm--title-desc {
  max-width: 620px;
  font-size: 1.05rem;
  margin-top: 20px;
}

.witrm--main-img {
  width: 100%;
  display: block;
  mix-blend-mode: screen;
  pointer-events: none;
}

.witrm--section {
  h2 {
    @include breakpoint(until-landscape) {
      font-size: 2.5rem;
    }

    @include breakpoint(until-portrait) {
      font-size: 2rem;
    }
  }
}

.witrm--section-1 {
  margin-top: 100px;
  padding-bottom: 20vw;

  @include breakpoint(until-landscape) {
    padding-bottom: 0;
  }

  .witrm--content {
    padding-top: 150px;

    @include breakpoint(until-landscape) {
      padding-top: 40px;
    }
  }

  .witrm--main-img {
    transform: scale(2) translate(-10%, -5%);
    transform-origin: 0 0;

    @include breakpoint(until-desktop) {
      transform: scale(2) translate(5%, -5%);
    }

    @include breakpoint(until-portrait) {
      transform: scale(1) translate(0, 0);
    }
  }
}

.witrm--section-2 {
  padding-top: 5vw;
  padding-bottom: 20vw;

  @include breakpoint(until-portrait) {
    margin-bottom: 200px;

    .col-md-6 {
      &:nth-child(1) { order: 2; }
      &:nth-child(2) { order: 1; }
    }
  }

  .witrm--main-img {
    transform: scale(2) translate(0%, -25%);
    transform-origin: 100% 0;

    @include breakpoint(until-portrait) {
      transform: scale(1.75) translate(0%, 0);
      transform-origin: 20% 0;
    }
  }
}

.witrm--section-3 {
  margin-bottom: 150px;

  .witrm--main-img {
    transform: scale(1.2) translate(5%, -28%);
    transform-origin: 100% 0;

    @include breakpoint(until-desktop) {
      transform: scale(1.2) translate(18%, -16%);
    }

    @include breakpoint(until-portrait) {
      transform: scale(1) translate(-3%, 0);
    }
  }
}

.witrm--section-4 {
  @include breakpoint(until-portrait) {
    margin-bottom: 230px;

    .col-md-6 {
      &:nth-child(1) { order: 2; }
      &:nth-child(2) { order: 1; }
    }
  }

  .witrm--main-img {
    transform: scale(1.2) translate(0, -23%);
    transform-origin: 100% 0;

    @include breakpoint(until-portrait) {
      transform: scale(1.5) translate(0, 0);
      transform-origin: 50% 30%;
    }
  }
}

.witrm--section-5 {
  margin-top: 100px;

  .col-title {
    margin-bottom: 50px;
  }

  .witrm-icon-item {
    text-align: center;

    @include breakpoint(until-portrait) {
      width: 90%;
      margin: 0 auto 40px;
    }

    .witrm-icon-img {
      max-width: 150px;
      max-height: 85px;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    p {
      width: 90%;
    }
  }
}

.witrm--rarity-nav {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  @include breakpoint(until-portrait) {
    justify-content: center;
    align-items: center;
    margin: 40px 0 10px;
  }

  .witrm--rarity-item {
    text-align: center;
    margin-right: 16px;
    opacity: 0.5;
    transition: all 0.2s;

    @include breakpoint(until-landscape) {
      margin-right: 10px;
    }

    a,
    button {
      display: block;
      background: none;
      box-shadow: none;
      border: none;
      cursor: pointer;
      width: 100%;
      padding: 0;
    }

    .witrm--rarity-img {
      width: 70px;
      height: auto;
      display: block;
      margin: 0 auto;
      border-radius: 35px;
      transition: all 0.2s;

      @include breakpoint(until-landscape) {
        width: 50px;
      }

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    small {
      width: auto;
      display: inline-block;
      font-size: 0.65rem;
      text-transform: uppercase;
      color: rgba($white, 0.8);
      font-weight: 700;
      margin-top: 8px;
      border-bottom: 2px solid $white;
      opacity: 0;
      pointer-events: none;
      transition: all 0.2s;
      transform: translateY(10px);
    }

    &:hover {
      opacity: 0.85;
    }

    &.witrm--rarity-item-active {
      opacity: 1;

      .witrm--rarity-img {
        box-shadow: 0 0 25px rgba($white, 0.35);
      }

      small {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
