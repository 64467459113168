@import 'scss/init';

.pg-marketplace {
  #main-content {
    background-size: 100% 800px;

    @include breakpoint(until-portrait) {
      background-size: 100% 350px;
    }
  }
}

.marketplace-filters {
  margin-top: 50px;
  margin-bottom: 120px;

  @include breakpoint(until-portrait) {
    margin-bottom: 70px;
  }
}

label.lbl-inline {
  @include no-select();

  position: relative;
  top: 6px;
  margin-left: 8px;
  transition: all 0.2s;
}

.form-element-container.sort {
  margin-top: -1.25em;
}

.search-txt {
  width: 100%;
  max-width: 550px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding: 9px 40px 9px 15px;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.25s;
  position: relative;
  background: url('~scss/media/ico-search.svg') 98% 5px no-repeat;
  background-size: 24px auto;

  @include breakpoint(until-landscape) {
    top: 0;
  }

  &:hover {
    color: rgba($white, 0.7);
    border-bottom-color: rgba($white, 0.6);
  }

  &:focus {
    color: rgba($white, 1);
    border-bottom-color: rgba($white, 0.8);
  }
}

input,
textarea {
  &::placeholder {
    color: rgba($white, 0.5);
  }
}

.filters-btn {
  background: rgba($black, 0.15) !important;
  border: 1px solid rgba($white, 0.4) !important;
  box-sizing: border-box !important;
  color: $white !important;
  cursor: pointer;
  font-size: 0.8125rem;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 25px 10px;
  display: inline-flex;
  align-items: center;
  outline: 0;
  border-radius: 0;

  @include breakpoint(until-landscape) {
    top: 0;
  }

  @include breakpoint(until-portrait) {
    &,
    &:hover {
      border: 0 !important;
      background: transparent !important;
    }
  }

  .ico {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url('~scss/media/ico-plus.svg') 50% 50% no-repeat;
    background-size: 20px auto;
    position: relative;
    margin-left: 15px;
    transition: all 300ms ease;

    @include breakpoint(until-portrait) {
      width: 16px;
      height: 16px;
      background-size: 16px auto;
      top: 3px;
      margin-left: 8px;
    }
  }

  &:hover {
    border-color: rgba($white, 0.6) !important;
    background: rgba($black, 0.3) !important;
  }

  &.filters-btn--opened {
    .ico {
      transform: rotate(45deg);
    }
  }

  small {
    font-size: 0.75rem;
    line-height: 1em;
    position: relative;
    top: -1px;
    font-weight: 300;
    margin-left: 4px;
    display: inline-block;
    color: $purple;
  }
}

.filters-clear-btn {
  color: $white;
  cursor: pointer;
  font-size: 0.8125rem;
  line-height: 1em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 12px 10px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
  border: 0;
  background: none;
  transition: color 0.1s linear, text-shadow 0.1s linear;

  @include breakpoint(until-landscape) {
    top: 0;
  }

  &:hover {
    color: $purple;
    text-shadow: 0 0 4px rgba($purple, 0.7);
  }
}

.hidden-search {
  display: none !important;
}

.vsb-menu {
  cursor: pointer;
  z-index: 1000;
  display: block;
  visibility: hidden;
  position: absolute; /* Don't change */
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 4px;
  font-size: 11px;
}

.vsb-js-search-zone {
  position: absolute; /* Don't change */
  z-index: 1001;
  width: 80%;
  min-height: 1.8em;
  padding: 2px;
  background-color: rgb(255, 255, 255);

  input {
    border: 1px solid $grey;
    margin-left: 2px;
    width: 96%;
    border-radius: 4px;
    height: 25px !important;
  }
}

.vsb-main {
  position: relative; /* Don't change */
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  @include breakpoint(until-portrait) {
    max-width: none;
  }
}

.vsb-menu li {
  display: list-item;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  min-height: 1.4em;
  padding: 0.2em 2em 0.2em 1em;

  &:hover {
    background: linear-gradient(rgb(245, 245, 245), rgb(232, 232, 232));
  }

  &.grouped-option {
    font-weight: 700;
    font-size: 15px;
    padding-left: 5px;
  }
}

.vsb-menu ul {
  user-select: none;
  list-style: none;
  white-space: nowrap;
  margin: 0;
  margin-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  color: rgb(51, 51, 51);
  cursor: pointer;
  overflow-y: auto;
}

li.disabled {
  cursor: not-allowed;
  opacity: 0.3;
  background-color: rgb(153, 153, 153);
}

.vsb-main button {
  min-width: 120px;
  border-radius: 0;
  width: 100%;
  text-align: left;
  z-index: 1;
  color: rgb(51, 51, 51);
  background: $white !important;
  border: 1px solid rgb(153, 153, 153) !important;
  line-height: 20px;
  font-size: 14px;
  padding: 6px 12px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.vsb-main .title {
  margin-right: 6px;
  user-select: none;
}

.vsb-main li:hover {
  background: linear-gradient(rgb(245, 245, 245), rgb(232, 232, 232));
}

.vsb-main ul {
  white-space: nowrap;
}

.vsb-menu :not(.multi) li.active::before {
  content: '';
  display: inline-block;
  font-size: inherit;
  float: left;
  margin-left: -16px;
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-bottom: 3px solid $black;
  border-right: 3px solid $black;
  border-radius: 2px;
}

.vsb-menu .multi li {
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  min-height: 1.4em;
  padding: 0.2em 2em 0.2em 26px;

  &.grouped-option {
    font-weight: 700;
    font-size: 15px;
    padding-left: 5px;
  }

  &:not(.grouped-option)::before {
    content: '';
    display: inline-block;
    font-size: inherit;
    float: left;
    font-weight: 700;
    margin-left: -22px;
    margin-right: 2px;
    border: 1px solid;
    border-radius: 3px;
    padding: 7px;
    margin-top: 0;
    color: $black;
  }

  &.active::after {
    content: '';
    display: inline-block;
    font-size: inherit;
    color: rgb(51, 51, 51);
    float: left;
    margin-left: -18px;
    transform: rotate(45deg);
    margin-top: 1px;
    height: 8px;
    width: 5px;
    border-bottom: 3px solid $black;
    border-right: 3px solid $black;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.marketplace-grid-container {
  .col-md-3 {
    @include breakpoint(until-landscape) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
