@import 'scss/init';

.pagination-nav {
  @include clearfix();
  @include no-select();

  width: 100%;
  text-align: center;
  position: relative;
  top: 17px;
  color: $white;

  ul {
    width: auto;
    display: inline-block;
    text-align: center;
  }

  li {
    float: left;
    display: block;
  }

  .page-item {
    a,
    &.active {
      width: auto;
      height: auto;
      box-sizing: border-box;
      display: block;
      padding: 9px 15px;
      font-weight: 700;
      color: $white;
      border: 2px solid transparent;
      font-size: 0.8125rem;
      letter-spacing: 0.02em;

      @include breakpoint(until-landscape) {
        padding: 5px 11px;
      }
    }

    &:not(.active) a:hover {
      color: $purple;
      text-shadow: 0 0 4px rgba($purple, 0.7);
    }

    &.active {
      border-color: $white;
      cursor: default !important;
    }
  }

  .pagination--arrow {
    width: 41px;
    height: 41px;
    display: block;
    background: no-repeat 50% 50%;
    background-size: 18px auto;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

    @include breakpoint(until-landscape) {
      height: 31px;
    }

    a.page-link {
      height: 100%;
    }

    &.pagination--arrow-prev {
      background-image: url('./assets/ico-pagination-arrow-left.svg');
      margin-right: 10px;

      &:hover {
        transform: translateX(-5px);
      }
    }

    .pagination--gap {
      color: $white;
      font-weight: 300;
      display: block;
      padding: 0;
      margin: 0;

      a.page-link,
      a.page-link:hover {
        color: $white;
        text-shadow: none;
        pointer-events: none;
        cursor: default;
      }
    }

    &.pagination--arrow-next {
      background-image: url('./assets/ico-pagination-arrow-right.svg');
      margin-left: 10px;

      &:hover {
        transform: translateX(5px);
      }
    }

    span {
      display: none;
    }
  }
}
