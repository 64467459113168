@import 'scss/init';

button {
  background: none;
  border: none;
  box-shadow: none;
}

.faqs--section {
  margin-top: 50px;

  @include breakpoint(until-landscape) {
    margin-top: 30px;
  }
}

.faqs--intro-desc {
  max-width: 790px;
  margin: -30px auto 0;
  text-align: center;

  @include breakpoint(until-landscape) {
    padding: 0 35px;
  }
}
