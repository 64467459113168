@import 'scss/init';

.upper-header__wrapper {
  width: 100%;
  background: rgba($white, 0.1);
  border-bottom: 1px solid rgba($white, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
  padding: 14px 0;
  margin-bottom: 40px;

  @include breakpoint(until-landscape) {
    display: none;
  }

  .col-r {
    text-align: right;
  }
}

.social-media-nav {
  overflow: hidden;
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 45px;

  @include breakpoint(until-desktop) {
    margin-right: 35px;
  }

  ul {
    overflow: hidden;
    display: block;
    text-align: left;
  }

  li {
    float: left;
    display: block;
    &:not(:last-child) { margin-right: 20px; }
  }

  a.social-item {
    @include hide-text();

    width: 18px;
    height: 18px;
    display: block;
    background: 50% 50% no-repeat;
    opacity: 0.8;

    &.social-telegram { background-image: url('assets/ico-social-telegram.svg'); }
    &.social-twitter { background-image: url('assets/ico-social-twitter.svg'); }
    &.social-youtube { background-image: url('assets/ico-social-youtube.svg'); }
    &.social-facebook { background-image: url('assets/ico-social-facebook.svg'); }
    &.social-instagram { background-image: url('assets/ico-social-instagram.svg'); }
    &.social-linkedin { background-image: url('assets/ico-social-linkedin.svg'); }

    &:hover {
      opacity: 1;
    }
  }
}

.btn.btn-fantasy {
  padding: 10px 12px 7px;

  img {
    width: 18px;
    position: relative;
    top: -2px;
    margin-left: 4px;
  }
}

.fevr-token-top-cta {
  @include clearfix();

  display: block;
  font-size: 0.9rem;
  line-height: 32px;
  font-weight: 300;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  top: 3px;

  &::before {
    content: '';
    width: 30px;
    height: 32px;
    background: url('assets/fevrtoken-header.png') 0 0 no-repeat;
    background-size: contain;
    display: block;
    float: left;
    margin-right: 10px;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &:hover {
    color: $purple;
    &::before { transform: rotate(-15deg) scale(1.1); }
  }

  + .has-submenu--list-wrapper a {
    font-size: 0.9rem !important;
  }
}

.tokens-league-btn {
  font-size: 0.8125rem;
  line-height: 1em;
  text-transform: uppercase;
  margin-right: 35px;

  @include breakpoint(until-desktop) {
    margin-right: 25px;
  }
}

.maintenance-message {
  height: 50px;
  padding: 15px;
  background: linear-gradient(186.61deg, #ff9800 0.5%, #ec682c 91.72%);
  text-align: center;
}
