@font-face {
  font-family: 'Azo Sans';
  src: url('../fonts/azo-sans-regular.eot');
  src: url('../fonts/azo-sans-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/azo-sans-regular.woff') format('woff'), url('../fonts/azo-sans-regular.woff2') format('woff2'), url('../fonts/azo-sans-regular.ttf') format('truetype'), url('../fonts/azo-sans-regular.svg#azo-sans-regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('../fonts/azo-sans-medium.eot');
  src: url('../fonts/azo-sans-medium.eot?#iefix') format('embedded-opentype'), url('../fonts/azo-sans-medium.woff') format('woff'), url('../fonts/azo-sans-medium.woff2') format('woff2'), url('../fonts/azo-sans-medium.ttf') format('truetype'), url('../fonts/azo-sans-medium.svg#azo-sans-medium') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('../fonts/azo-sans-bold.eot');
  src: url('../fonts/azo-sans-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/azo-sans-bold.woff') format('woff'), url('../fonts/azo-sans-bold.woff2') format('woff2'), url('../fonts/azo-sans-bold.ttf') format('truetype'), url('../fonts/azo-sans-bold.svg#azo-sans-bold') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Azo Sans';
  src: url('../fonts/azo-sans-black.eot');
  src: url('../fonts/azo-sans-black.eot?#iefix') format('embedded-opentype'), url('../fonts/azo-sans-black.woff') format('woff'), url('../fonts/azo-sans-black.woff2') format('woff2'), url('../fonts/azo-sans-black.ttf') format('truetype'), url('../fonts/azo-sans-black.svg#azo-sans-black') format('svg');
  font-weight: 700;
  font-style: normal;
}

$fonts: 'Azo Sans', arial, helvetica, sans-serif;
