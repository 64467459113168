@import 'scss/init';

.return-link-container {
  margin-top: 60px;
  text-align: center;
}

.item-detail-link,
.return-link {
  font-size: 0.75rem;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  color: rgba($white, 0.7);
  font-weight: 500;
  background: none;
  border: 0;

  &:hover {
    color: rgba($green, 1);
  }
}
