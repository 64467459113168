@import 'scss/init';

.pg-how-to-collect {
  #main-content {
    background-size: 100% 100%;
  }
}

.howtocollect--video {
  position: relative;
  padding-bottom: 53.25%;
  width: 95%;
  height: 0;
  box-shadow: -40px 60px 94px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin-bottom: 0;

  @include breakpoint(until-landscape) {
    width: 100%;
    margin-bottom: 50px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  video {
    width: 100%;
    height: auto;
  }
}

.howtocollect--section {
  p {
    a {
      color: $green;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .inline-svg-icon {
    width: 15px;
    height: auto;
    display: inline-block;
    margin-left: 5px;
  }

  .expandable-items--container {
    margin-top: 30px;

    .expandable-items--trigger {
      font-weight: 500;
      font-size: 1.1em;
    }

    .expandable-items--content {
      font-size: 0.9rem;
    }
  }

  ol {
    margin-top: 20px;
    margin-left: 10px;

    li {
      margin-bottom: 15px;
      counter-increment: line-number;
      position: relative;
      padding-left: 32px;

      &::before {
        width: 25px;
        height: 25px;
        background: $white;
        border-radius: 15px;
        text-align: center;
        font-size: 0.8rem;
        line-height: 27px;
        font-weight: 500;
        color: $blue_dark;
        content: attr(data-listing-number) ''counter(line-number);
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(0, -3px);
      }

      ol {
        padding-top: 5px;
        margin-bottom: 20px;
      }

      li {
        text-transform: none;
        margin-bottom: 17px;
        counter-increment: line-number-sub;

        &::before {
          content: attr(data-listing-number);
        }
      }
    }

    // stylelint-disable-next-line no-descending-specificity
    a {
      color: $white;
      text-decoration: none;

      &:hover {
        color: $green;
      }
    }
  }
}

.howtocollect--section-1 { margin-top: 120px; }

.howtocollect--section-2,
.howtocollect--section-3 { margin-top: 180px; }

.howtocollect--section-2 {
  @include breakpoint(until-portrait) {
    .col-md-6:nth-child(1) { order: 2; }
    .col-md-6:nth-child(2) { order: 1; }
  }

  .howtocollect--video {
    margin-left: 5%;

    @include breakpoint(until-landscape) {
      margin-left: 0;
    }
  }
}

.howtocollect--section-3 { margin-bottom: 250px; }
