@import 'scss/init';

.form--feedback {
  margin: 70px 0 100px;
  font-size: 1.25rem;
  line-height: 1.3em;
  font-weight: 500;
  text-transform: uppercase;
}

.form--req {
  transform: scale(1.25);
  display: inline-block;
  margin-left: 2px;
}

.form--elem {
  margin-bottom: 30px;

  ::placeholder {
    color: rgba($white, 0.5);
  }

  .form--lbl {
    width: 100%;
    display: block;
    margin-bottom: 3px;
  }

  input[type='text'],
  input[type='password'],
  input[type='color'],
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='url'],
  input[type='week'],
  input[type='search'],
  input[type='tel'],
  textarea {
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 0;
    border-bottom: 1px solid rgba($white, 0.6);
    box-sizing: border-box;
    color: $white;
    transition: all 0.2s, height 0s;
    background: transparent;

    &:hover {
      border-bottom-color: rgba($white, 0.8);
    }

    &:focus {
      color: $white;
      border-bottom-color: rgba($green, 1);
    }
  }

  // stylelint-disable-next-line no-descending-specificity
  textarea {
    min-height: 98px;
    padding-left: 15px;
    border: 1px solid rgba($white, 0.6);
    resize: vertical;

    &:focus {
      border-color: rgba($green, 1);
    }
  }

  &.form--elem-error {
    .form--req {
      color: $red;
      font-weight: 600;
    }

    input,
    textarea,
    .react-select__control {
      border-color: $red !important;
    }
  }
}

.form-elem--error-desc {
  font-size: 0.6rem;
  line-height: 1em;
  font-weight: 500;
  display: block;
  color: $red;
  margin-top: 9px;
  margin-bottom: -10px;
}

html .form--elem {
  .vsb-main,
  .vsb-main button {
    max-width: none !important;
    width: 100% !important;
    background: transparent !important;
  }

  .vsb-main button {
    border: 1px solid rgba($white, 0.6) !important;
    transition: border 0.2s;
  }

  .vsb-main:not(.vsb-active) button:hover {
    background: transparent !important;
    border-color: rgba($white, 0.7) !important;
  }
  .vsb-main button .caret { top: 10px !important; }

  &.form--elem-error {
    .vsb-main:not(.vsb-active) button { border-color: $red !important; }
  }
}
