@import 'scss/init';

.video-sound-control--container {
  video {
    position: relative;
    z-index: 10;
  }
}

.video-sound-control--btn {
  width: 32px;
  height: 32px;
  display: block;
  position: absolute;
  bottom: 40px;
  left: 45px;
  z-index: 1000;
  border: 0;
  background: url('./assets/ico-sound-on.svg') 50% 50% no-repeat;
  background-size: 32px 32px;
  cursor: pointer;
  transition: none;

  &.video-sound-control--btn-off {
    background-image: url('./assets/ico-sound-off.svg');
  }
}
