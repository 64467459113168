@import 'scss/init';

.bullet-list li {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 12px;
  position: relative;

  &::before {
    content: '•';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }
}

.cd-cta-error {
  margin-left: 5px;
  color: $purple;
}

.cd-cta {
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;

  .nav-item-submenu {
    li {
      display: inline-block;
    }
  }
}

.pack--img {
  &,
  .pack & {
    width: 90%;
    transform: scale(1);
    position: relative;
    mix-blend-mode: screen;

    img {
      width: 100%;
      display: block;
    }

    .pack--img-item {
      width: 100%;
      display: block;
      position: relative;
      z-index: 20;
      transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

      img {
        position: relative;
        z-index: 10;
      }
    }
  }

  .pack--img-shape {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    top: -5%;
    left: 12%;
    z-index: 10;
    transition: all 0.1s linear;
    opacity: 0.5;
  }
}

.container-more-collectibles,
.container-inside-pack {
  margin-top: 100px;

  .col-md-3 {
    @include breakpoint(until-landscape) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.container-inside-pack {
  .pack-contents--desc {
    max-width: 800px;
    margin: -40px auto 50px;
    text-align: left;

    &.center {
      text-align: center;
    }
  }

  .pack-contents--diagram {
    max-width: 660px;
    display: block;
    margin: 0 auto;

    // stylelint-disable-next-line no-descending-specificity
    img {
      width: 100%;
      display: block;
    }
  }

  .pack-contents--more-details {
    margin-top: 50px;
    text-align: center;
    p { margin-bottom: 10px; }
  }

  .container-check-ico {
    max-width: 1000px;
    margin: 0 auto;
  }

  .check-ico-item {
    position: relative;
    margin-bottom: 15px;
    padding-left: 25px;
    padding-right: 10px;

    &::before {
      content: '';
      width: 19px;
      height: 19px;
      position: absolute;
      top: -2px;
      left: -2px;
      transform: translate(0, 0);
      background: url('../../../scss/media/ico-check.svg') 50% 50% no-repeat;
      background-size: 19px auto;
    }
  }
}

.pack-holder {
  @include breakpoint(until-landscape) {
    .modal-window & {
      .pack--img {
        max-width: 200px;
        margin: 55px auto 30px;
      }

      .collectible-holder--details { text-align: center; }
    }
  }

  @include breakpoint(until-portrait) {
    overflow-x: hidden;

    .col-md-5,
    .col-md-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .collectible-holder--img { margin-top: 30px; }

    .collectible-holder--details {
      margin-top: 30px;
    }
  }

  .collectible-holder--img {
    @include breakpoint(until-portrait) {
      margin-top: 30px;
    }
  }

  .pack--img {
    width: 80%;

    @include breakpoint(until-portrait) {
      position: relative;
      left: 10%;

      .modal-window & {
        left: -2%;
        max-width: 150px;
        margin: 0 auto;
      }
    }

    &::after { right: -43.5%; }
    .pack--img-shape { left: 15%; }
  }

  .pack--details {
    .cd-description {
      margin-bottom: 35px;

      p,
      ul.bullet-list {
        max-width: 600px;
      }
    }
  }
}

.collectible-holder,
.pack-holder,
.top-sales-listing--row {
  .pg-collectible & {
    @include breakpoint(until-desktop) {
      margin-top: 80px;
    }

    @include breakpoint(until-portrait) {
      margin-top: -40px;
    }
  }

  .pg-collectible &,
  .pg-pack & {
    @include breakpoint(until-landscape) {
      margin-top: 0;
    }
  }

  .collectible-holder--media-wrap {
    width: 88%;
    position: relative;

    @include breakpoint(until-wide) {
      width: 100%;

      .modal-window & {
        left: -5%;
      }
    }

    @include breakpoint(until-desktop) {
      left: -5%;
    }

    @include breakpoint(until-landscape) {
      width: 78%;
      display: inline-block;

      .modal-window & {
        width: 100%;
        left: 0;
      }
    }

    @include breakpoint(until-portrait) {
      width: 100%;
      left: 0;
    }
  }

  .collectible--media-gallery {
    width: 100%;
    position: relative;

    @include breakpoint(until-semiwide) {
      margin-top: -20px;
    }

    .collectible--media-gallery-item {
      @include clearfix();

      width: 100%;
      opacity: 0;
      mix-blend-mode: screen;
      transition: opacity 0.25s;
      position: relative;
      z-index: 10;
      pointer-events: none;

      &.active-media-item {
        opacity: 1;
        z-index: 20;
        pointer-events: all;
      }

      .collectible--video-animation {
        top: 0;
      }

      .video-sound-control--btn {
        transform: translate(45px, -40px);
        bottom: -30%;
        left: -15%;

        @include breakpoint(until-wide) {
          bottom: -18%;
          left: -6%;

          .modal-window & {
            bottom: -32%;
            left: -14px;
          }
        }

        @include breakpoint(until-semiwide) {
          bottom: -14%;
          left: -2%;
        }

        @include breakpoint(until-desktop) {
          bottom: -19%;
          left: -4%;
        }

        @include breakpoint(until-landscape) {
          .modal--window & {
            bottom: -15%;
            left: -2%;
          }
        }

        @include breakpoint(until-portrait) {
          bottom: -32%;
          left: -9%;

          .modal-window & {
            bottom: -32%;
            left: -6%;
          }
        }
      }

      &:not(.collectible--media-size-definer) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .collectible--video {
    top: 60px;
    left: 0;
    transform: scale(1.3);
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    animation-delay: 0.1s;
  }

  .collectible--img {
    @include no-select();

    display: inline-block;
    transform: scale(1.35);
    z-index: 10;
    transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  }

  .collectible--video,
  .collectible--img {
    width: 100%;
    position: relative;
    transform-origin: 50% 50%;
    pointer-events: none;

    @include breakpoint(until-wide) {
      transform: scale(1.1);

      .modal-window & {
        transform: scale(1.2);
      }
    }

    @include breakpoint(until-wide) {
      transform: scale(1);
    }

    @include breakpoint(until-landscape) {
      .modal-window & {
        transform: scale(1);
      }
    }
  }

  .collectible--media-gallery-nav {
    @include clearfix();

    width: 100%;
    position: relative;
    z-index: 20;
    text-align: center;
    margin-top: 70px;

    @include breakpoint(until-wide) {
      margin-top: 50px;
    }

    @include breakpoint(until-landscape) {
      margin-top: 0;

      .modal-window & {
        margin-top: 20px;
      }
    }

    @include breakpoint(until-portrait) {
      width: 120%;
      position: relative;
      left: -10%;
    }

    .collectible--media-gallery-nav-item {
      width: 70px;
      height: auto;
      display: inline-block;
      border: 1px solid rgba($white, 0.4);
      opacity: 0.7;
      transition: all 0.25s;

      @include breakpoint(until-desktop) {
        .modal-window & {
          width: 55px;
        }
      }

      @include breakpoint(until-portrait) {
        width: 45px;
      }

      &:not(:last-child) {
        margin-right: 17px;

        @include breakpoint(until-portrait) {
          margin-right: 12px;
        }
      }

      &:hover {
        opacity: 1;
        box-shadow: 0 0 20px rgba($white, 0.4);
      }

      button {
        border: 0;
        background: none;
        cursor: pointer;
      }

      &.active-nav-item {
        opacity: 1;
        box-shadow: 0 0 20px rgba($white, 0.8);
        border: 1px solid rgba($white, 0.65);

        button { cursor: default; }
      }

      img { width: 100%; }
    }
  }

  .collectible-holder--img,
  .collectible-holder--details {
    @include breakpoint(until-landscape) {
      &,
      .modal-window & {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  .collectible-holder--img {
    @include breakpoint(until-landscape) {
      text-align: center;
    }
  }

  .collectible-holder--details {
    text-align: center;

    @include breakpoint(until-landscape) {
      margin-top: 50px;

      .modal-window & {
        text-align: left;
      }
    }
  }

  .btn {
    min-width: 210px;
  }

  .collectible--details,
  .pack--details {
    display: inline-block;
    text-align: left;
    margin-top: 0;

    .cd-badge {
      margin-bottom: 15px;

      img {
        width: auto;
        height: 25px;
        display: block;

        @include breakpoint(until-portrait) {
          height: 20px;
        }
      }
    }

    .cd-h1 {
      max-width: 500px;
      font-size: 3.2rem;
      line-height: 1em;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      margin-bottom: 4px;

      @include breakpoint(until-desktop) {
        font-size: 2.8rem;
      }

      @include breakpoint(until-landscape) {
        font-size: 2.8rem;
      }

      @include breakpoint(until-portrait) {
        font-size: 2rem;
      }
    }

    .cd-h2,
    .cd-h3,
    .cd-h4 {
      margin-bottom: 8px;
      font-size: 0.75rem;
      line-height: 1em;
      text-transform: uppercase;
      color: rgba($white, 0.7);
      font-weight: 500;
      letter-spacing: 0.15em;

      @include breakpoint(until-portrait) {
        line-height: 1.5em;
      }

      strong {
        color: rgba($white, 1);
        font-weight: 500;
      }
    }

    .cd-h2 {
      width: 95%;
      line-height: 1.3em;
    }

    .cd-h3 {
      color: $green;
    }

    .cd-h4 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .cd-description {
      margin-bottom: 25px;
      max-width: 500px;

      p {
        line-height: 1.4em;
      }

      .modal-window & {
        display: none;
      }
    }

    .cd-return-link {
      margin-bottom: 25px;
      max-width: 500px;
      display: none;

      .item-detail-link {
        font-size: 0.825rem;
        color: $white;

        &:hover,
        &:focus {
          color: $green;
        }
      }

      .modal-window & {
        display: block;
      }
    }

    .cd-specs,
    .cd-bottom-specs {
      font-size: 0.75rem;
      line-height: 1em;
      letter-spacing: 0.1em;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 28px;

      strong { font-weight: 500; }
      li { margin-bottom: 10px; }
    }

    .cd-bottom-specs {
      border-top: 1px solid rgba($white, 0.5);
      margin-top: 28px;
      padding-top: 15px;
      font-size: 0.625rem;
      color: rgba($white, 0.8);
    }

    .cd-price--lbl {
      font-size: 0.625rem;
      line-height: 1em;
      text-transform: uppercase;
      margin-bottom: 8px;
      font-weight: 300;
    }

    .cd-price--fevr {
      font-size: 2.5rem;
      line-height: 1em;
      letter-spacing: 0.02em;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 5px;

      small {
        font-size: 1.35rem;
        line-height: 1em;
        font-weight: 500;
        position: relative;
        top: -12px;
        margin-left: 3px;
      }
    }

    .cd-price--exchange {
      font-size: 1.2rem;
      line-height: 1em;
      letter-spacing: 0.02em;
      color: rgba($white, 0.8);
      font-weight: 400;
      text-transform: uppercase;

      small {
        font-size: 0.5rem;
        line-height: 1em;
        font-weight: 500;
        position: relative;
        top: -7px;
        margin-left: 3px;
      }
    }

    .cd-royalty {
      color: rgba($white, 0.7);
      margin-top: 8px;
    }

    .cd-quantity {
      margin-top: 30px;
      .vsb-main { max-width: 210px !important; }
    }

    .btn {
      margin-top: 20px;
    }

    .cd-service-fee {
      color: rgba($white, 0.7);
      margin-top: 15px;
    }

    .cd-warning {
      color: rgba($red, 0.7);
      margin-top: 15px;
    }

    .cd-license {
      font-size: 0.75rem;
      line-height: 1em;
      letter-spacing: 0.1em;
      color: $white;
      text-transform: uppercase;
      margin-bottom: 28px;
      margin-top: 25px;

      strong { font-weight: 500; }

      .lbl {
        display: block;
        margin-bottom: 8px;
      }

      .licensor {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .licensor-logo {
          max-width: 140px;
          max-height: 60px;
        }

        .licensor-name {
          font-size: 0.6rem;
          font-weight: 400;
          position: relative;
          top: 2px;
          margin-left: 8px;
        }
      }
    }
  }

  .btn-share {
    min-width: 190px;
    background: none;
    color: $purple;
    filter: brightness(100%);
    transition: all 0.15s;

    @include breakpoint(until-landscape) {
      .modal-window .collectible-holder &,
      .modal-window .pack-holder & {
        padding-left: 40px;
      }
    }

    @include breakpoint(until-portrait) {
      margin: 0;
      min-width: 0;
      margin-top: 0 !important;
    }

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      top: 7px;
      background: url('./assets/ico-share.svg') 50% 50% no-repeat;
      background-size: 24px auto;
      margin-left: 8px;
      transition: all 0.15s;
      mix-blend-mode: normal;
      opacity: 1 !important;
    }

    &:hover {
      filter: brightness(300%);
    }
  }
}
