@import 'scss/init';

.top-sales-listing {
  padding-top: 25px;
  margin-bottom: 100px;

  @include breakpoint(until-landscape) {
    .top-sales-listing--item {
      .ico-info::after {
        left: auto;
        right: 0;
        transform-origin: 100% 0;
      }
    }

    .top-sales-listing--item-col-1 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .top-sales-listing--item-col-2 {
      flex: 0 0 28%;
      max-width: 28%;
    }

    .top-sales-listing--item-col-3 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .top-sales-listing--item-col-4 {
      flex: 0 0 15%;
      max-width: 15%;
    }

    .top-sales-listing--item-col-5 {
      flex: 0 0 10%;
      max-width: 10%;
    }

    .top-sales-listing--item-col-6 {
      flex: 0 0 5%;
      max-width: 5%;
    }
  }

  @include breakpoint(until-portrait) {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .top-sales-listing--header {
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: '';
      width: calc(100% + 20px);
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: -10px;
      background: rgba($white, 0.2);
    }

    .top-sales-listing--item {
      position: relative;
      vertical-align: top;

      .top-sales-listing--item-title {
        font-size: 0.9375rem;
        line-height: 1em;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: $green;
      }

      .ico-info {
        width: 10px;
        height: 10px;
        position: relative;
        top: -11px;
        margin-bottom: -16px;
        background-size: 13px auto;
        padding: 11px;

        @include breakpoint(until-landscape) {
          position: absolute;
          top: -3px;
          right: -25px;
        }
      }
    }
  }

  .top-sales-listing--img {
    width: 40%;
    margin-left: 5px;

    @include breakpoint(until-landscape) {
      width: 80%;
    }

    img {
      width: 100%;
      display: block;
      mix-blend-mode: screen;
      transform: scale(1.8);
      position: relative;
      top: -15px;
    }
  }

  .top-sales-listing--row {
    position: relative;
    padding-top: 45px;
    padding-bottom: 20px;
    align-items: center;

    @include breakpoint(until-portrait) {
      min-width: 680px;
      padding-left: 15px;
      padding-right: 25px;
    }

    &::after {
      content: '';
      width: calc(100% + 20px);
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: -10px;
      background: rgba($white, 0.2);
    }

    .top-sales-listing--item {
      .cd-h1 {
        font-size: 1.35rem;
        line-height: 1em;
        color: $white;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        margin-bottom: 4px;

        @include breakpoint(until-landscape) {
          font-size: 1.2rem;
        }

        a,
        a:hover {
          color: $white;
        }
      }

      .cd-h2 {
        margin-bottom: 0;
        font-size: 0.75rem;
        line-height: 1.3em;
        text-transform: uppercase;
        color: rgba($white, 0.7);
        font-weight: 500;
        letter-spacing: 0.15em;

        strong {
          color: rgba($white, 1);
          font-weight: 500;
        }
      }

      .cd-price--fevr {
        font-size: 1.8rem;
        line-height: 1em;
        letter-spacing: 0.02em;
        color: $white;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 7px;

        @include breakpoint(until-landscape) {
          font-size: 1.5rem;
        }

        small {
          font-size: 1rem;
          line-height: 1em;
          font-weight: 500;
          position: relative;
          top: -9px;
          margin-left: 3px;

          @include breakpoint(until-landscape) {
            font-size: 0.8rem;
            top: -8px;
          }
        }
      }

      .cd-price--exchange {
        font-size: 1.1rem;
        line-height: 1em;
        letter-spacing: 0.02em;
        color: rgba($white, 0.8);
        font-weight: 400;
        text-transform: uppercase;

        @include breakpoint(until-landscape) {
          font-size: 0.9rem;
        }

        small {
          font-size: 0.5rem;
          line-height: 1em;
          font-weight: 500;
          position: relative;
          top: -7px;
          margin-left: 3px;

          @include breakpoint(until-landscape) {
            font-size: 0.4rem;
            top: -5px;
          }
        }
      }

      .cd-badge {
        margin-bottom: 0;

        img {
          width: auto;
          height: 24px;
          display: block;

          @include breakpoint(until-landscape) {
            height: 16px;
          }
        }
      }

      .btn-transaction {
        min-width: 0;
      }
    }

    .ico-transaction {
      width: 30px;
      height: 30px;
      display: block;
      background: url('./assets/ico-transaction.svg') 50% 50% no-repeat;
      background-size: 20px auto;
      opacity: 0.8;

      &:hover { opacity: 1; }
    }
  }
}
