@import 'scss/init';

.ico-info {
  width: 16px;
  height: 16px;
  padding: 15px;
  display: inline-block;
  background: url('./assets/ico-info.svg') 50% 50% no-repeat;
  background-size: 16px auto;
  opacity: 0.8;
  transition: all 0.1s;

  &::after {
    @include breakpoint(until-landscape) {
      transform: translate(0, 110%) scale(0);
      left: 0;
      transform-origin: 0 0;
    }

    @include breakpoint(until-portrait) {
      min-width: 150px;
    }
  }

  &:hover {
    opacity: 1;

    &::after {
      @include breakpoint(until-landscape) {
        // transform: translate(0, 110%) scale(1);
        transform: translate(0, -30%) scale(1);
        background-color: rgba(46, 60, 83, 0.95);
      }
    }
  }
}

.tooltip-info {
  max-width: 350px;
}
