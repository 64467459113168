@import 'scss/init';

.section--newsletter {
  margin-top: 150px;
  margin-bottom: 50px;

  .modal-window & {
    margin-top: 30px;
    margin-bottom: 0;

    @include breakpoint(until-portrait) {
      margin-top: 40px;
    }

    .title-lines {
      display: none;
    }
  }

  .intro-p {
    max-width: 750px;
    text-align: center;
    margin: -40px auto 40px;

    .modal-window & {
      margin-top: 0;
    }
  }

  #mc_embed_signup {
    max-width: 650px;
    margin: 0 auto;

    * { text-align: center; }

    div.mce_inline_error {
      font-size: 0.6rem;
      line-height: 1em;
      font-weight: 500;
      display: block;
      color: $red;
      margin: 0;
      padding: 0;
      background: transparent;
    }

    .form--elem { margin-bottom: 10px; }

    #mc-embedded-subscribe { margin-top: 0; }

    .submit-btn-container {
      width: 100%;
      text-align: center;
    }

    #mce-responses {
      margin-top: 20px;
      margin-bottom: 10px;

      &,
      .response {
        text-align: center;

        a {
          color: $red;
          text-decoration: underline;
        }
      }
    }

    #mce-success-response {
      color: $white;
      font-weight: 500;
    }

    #mce-error-response { color: $red; }

    .mc_form_terms {
      display: block;
      margin: 20px auto 0;
      max-width: 660px;
      text-align: center;
      color: rgba($white, 0.6);

      // stylelint-disable-next-line no-descending-specificity
      a {
        color: rgba($white, 0.6);
        text-decoration: underline;

        // stylelint-disable-next-line no-descending-specificity
        &:hover {
          color: $green;
          text-decoration: none;
        }
      }
    }
  }
}
