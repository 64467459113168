@import 'scss/init';

.filter--range {
  margin-left: 12px;
}

.rc-slider-rail,
.rc-slider-track {
  height: 1px;
  background-color: rgba($white, 0.35);
}

.rc-slider-track {
  background-color: rgba($white, 1);
}

.rc-slider-handle {
  width: 25px;
  height: 25px;
  box-shadow: 0 6px 10px rgba($black, 0.25);
  border: 1px solid rgba($white, 0.4);
  border-radius: 27px;
  margin-top: -12px;
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M4 9L4 0L5 0L5 9H4Z' fill='black'/%3E%3Cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M0 9L0 0L1 0L1 9H0Z' fill='black'/%3E%3Cpath opacity='0.4' fill-rule='evenodd' clip-rule='evenodd' d='M8 9V0L9 0V9H8Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 9px 9px;

  &:hover {
    border: 1px solid rgba($white, 0.4);
    box-shadow: 0 0 1px 1px $purple;
  }
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: $purple;
  box-shadow: 0 0 2px 1px $purple;
}

.filter--range-labels {
  @include no-select();

  width: 110%;
  overflow: hidden;
  margin-top: 15px;
  position: relative;
  left: -5%;
  padding: 3px 0;

  .filter--range-label {
    width: 50%;
    display: block;
    float: left;
    font-size: 0.7rem;
    line-height: 1em;

    small {
      font-size: 0.55rem;
      line-height: 1em;
      position: relative;
      top: -2px;
      margin-left: 3px;
    }
    &.filter--range-min { text-align: left; }
    &.filter--range-max { text-align: right; }
  }
}

@include breakpoint(until-landscape) {
  .filter--range,
  .noUi-horizontal {
    margin: 0 50px;
  }
}
