
/* RESET */
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
dialog,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
rp,
rt,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video,
xmp {
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: 0 0;
}

body,
html {
  color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  line-height: 1;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

b,
strong {
  font-weight: 700;
}

img {
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  // interpolation-mode: bicubic;
}

li {
  display: list-item;
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
td,
th {
  font-weight: 400;
  vertical-align: top;
  text-align: left;
}

small,
sub,
sup {
  font-size: 75%;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  font-size: 0.65em;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

svg {
  overflow: hidden;
}

address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: 400;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
}

q::after,
q::before {
  content: '';
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: rgb(0, 0, 0);
}

del,
ins {
  text-decoration: none;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

a {
  text-decoration: none;
}

input,
textarea {
  resize: none;
  padding: 0;
  margin: 0;
  outline: 0;
  border: none;
  overflow: auto;
  background: rgb(255, 255, 255);
  vertical-align: top;
}

input:focus {
  outline: 0;
}

i {
  font-style: normal;
}

input[type='submit'] {
  overflow: visible;
}
