@mixin hide-text () {
  overflow: hidden;
  text-indent: -9999px;
}

@mixin no-select () {
  user-select: none;
  cursor: default;
}

@mixin clearfix () {
  &::after {
    visibility: hidden;
    display: block;
    content: '';
    clear: both;
    font-size: 0;
    height: 0;
  }
}

@mixin font-smoothing () {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
