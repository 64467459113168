@import 'scss/init';

.title-lines {
  width: 100%;
  font-size: 2.8rem;
  line-height: 1em;
  letter-spacing: 0.02em;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: $white;
  background: url('./assets/diagonal-lines-title.svg') 50% 50% no-repeat;
  background-size: auto 100%;
  padding: 25px 0;
  margin-bottom: 70px;
  text-shadow: 0 0 15px rgba(13, 21, 62, 0.7);

  @include breakpoint(until-desktop) {
    font-size: 2.3rem;
  }

  @include breakpoint(until-landscape) {
    font-size: 2.2rem;
  }

  @include breakpoint(until-portrait) {
    font-size: 2rem;
  }

  &.title-lines-purple {
    background-image: url('./assets/diagonal-lines-title-purple.svg');
  }

  &.title-lines-x2 {
    background-image: url('./assets/diagonal-lines-title-multiline.svg');

    &.title-lines-purple {
      background-image: url('./assets/diagonal-lines-title-multiline-purple.svg');
    }
  }

  &.title-lines-smaller {
    font-size: 2.25em;
  }

  &.purple {
    color: $color-purple;
  }
}

.title-coming-soon {
  font-size: 2rem;
  display: block;

  @include breakpoint(until-portrait) {
    font-size: 1.5rem;
  }
}
