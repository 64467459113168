@import 'scss/init';

.pg-pack {
  #main-content {
    background-size: 100% 600px;

    @include breakpoint(until-portrait) {
      background-size: 100% 350px;
    }
  }

  .pack-holder {
    margin-top: 120px;
    margin-bottom: 70px;
  }
}
