@import 'scss/init';

.pg-collectible {
  #main-content {
    background-size: 100% 600px;

    @include breakpoint(until-portrait) {
      background-size: 100% 350px;
    }
  }

  .collectible-holder {
    margin-top: 120px;
    margin-bottom: 70px;
  }
}
