@import 'scss/init';

.pg-mycollection {
  #main-content {
    background-size: 100% 800px;

    @include breakpoint(until-portrait) {
      background-size: 100% 350px;
    }
  }

  .tabs-nav {
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .marketplace-filters {
    margin-top: -50px;
    margin-bottom: 0;

    @include breakpoint(until-portrait) {
      margin-top: -10px;
      margin-bottom: 20px;
    }

    .marketplace-filters--lvl2::after {
      display: none;

      @include breakpoint(until-portrait) {
        padding: 0;
      }
    }
  }

  .mycollection-collectibles-title {
    position: relative;
    padding: 20px 0 30px;

    small {
      width: 100%;
      height: auto;
      display: block;
      text-align: center;
      position: absolute;
      bottom: 0;
      font-size: 0.9rem;
    }
  }

  .ico-info {
    margin-left: 5px;
    position: relative;
    top: 12px;

    @include breakpoint(until-portrait) {
      margin-left: -5px;
    }
  }

  .col-align-right {
    @include breakpoint(until-portrait) {
      text-align: center;
    }
  }

  .container--mycollection-packs,
  .container--mycollection-collectibles {
    @include breakpoint(until-landscape) {
      .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .row-header {
        margin-bottom: 40px;

        .col {
          &:nth-child(1) { order: 2; }

          &:nth-child(2) {
            order: 1;
            flex-basis: unset;
            width: 100%;
          }
          &:nth-child(3) { order: 3; }
        }

        .title-lines { margin-bottom: 30px; }
      }
    }
  }

  .container--mycollection-collectibles {
    margin-top: 50px;

    @include breakpoint(until-portrait) {
      .row-header {
        .col {
          flex-basis: unset;
          width: 100%;

          &:nth-child(3) { margin-top: 30px; }
        }
      }
    }
  }

  .btn--outline-white {
    border-color: rgba($white, 0.65);
    color: rgba($white, 0.65);

    &:hover {
      border-color: rgba($white, 1);
      color: rgba($white, 1);
    }
  }
}

.form-element-container--align-right {
  float: right;

  @include breakpoint(until-portrait) {
    float: none;
  }

  label {
    width: 100%;
    max-width: 230px;
    text-align: left;
    display: block !important;
    float: right;

    @include breakpoint(until-portrait) {
      max-width: none;
    }
  }
}
