/* TO REMOVE */
$color-text: #fff;
$color-green: #30e06f;
$color-green-dark: #118139;
$color-green-faded: #74857a;
$color-purple: #e76af7;
$color-purple-dark: #a034ae;
$color-blue: #6a84cb;
$color-blue-dark: #2e457e;

/* ----------- */

$black: #000;
$white: #fff;
$green: #30e06f;
$purple: #e76af7;
$blue_dark: #0d153e;
$blue_light: #1d3575;
$red: #f4373c;
$grey: #808080;
$gradient_white: linear-gradient(178.9deg, $white 31.24%, $color-green-faded 182.59%);
$gradient_green: linear-gradient(141.32deg, $color-green 4.58%, $color-green-dark 122.96%);
$gradient_purple: linear-gradient(119.91deg, $color-purple -18.1%, $color-purple-dark 82.06%);
