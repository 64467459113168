@import 'scss/init';

.selectbox {
  width: 100%;
  cursor: pointer;
  max-width: 230px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  z-index: 60;

  &.selectbox--is-open {
    z-index: 61;
  }

  .react-select__control {
    background: rgba($black, 0.15) !important;
    border: 1px solid rgba($white, 0.4) !important;
    box-sizing: border-box !important;
    color: $white !important;
    cursor: pointer;
    outline: 0;
    border-radius: 0;

    &:hover {
      border-color: rgba($white, 0.6) !important;
      background: rgba($black, 0.3) !important;
    }

    &:focus,
    &:active {
      outline: 0;
    }
  }

  .react-select__value-container {
    font-size: 0.95rem !important;
    line-height: 1em !important;
    padding: 11px 15px 9px !important;
    transition: all 0.1s;
    outline: 0;
    flex-wrap: nowrap;

    &:focus,
    &:active {
      outline: 0;
    }

    .react-select__single-value {
      color: $white !important;
      outline: 0;
    }
  }

  .react-select__indicators {
    padding: 11px 15px 9px 5px !important;

    .react-select__clear-indicator {
      // padding: 0 8px;
      display: none;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      width: 20px;
      height: 20px;
      border: 0;
      background: url('~scss/media/ico-arrow-down.svg') 50% 50% no-repeat;
      background-size: contain;
      right: 15px !important;
      top: 8px !important;
      margin-top: 0 !important;
      transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

      svg {
        display: none;
      }
    }
  }

  .react-select__placeholder {
    color: $white;
  }

  .count {
    display: flex;
    margin: 2px;
    min-width: 0;
    box-sizing: border-box;
  }

  .react-select__menu {
    width: 100%;
    background: rgba($black, 0.95) !important;
    border-radius: 0;
    margin-top: -1px;
    border: 1px solid rgba(255, 255, 255, 0.4);

    .react-select__menu-list {
      padding-left: 15px;
      padding-right: 15px;
    }

    .react-select__option {
      background: none;
      color: rgba($white, 0.65);
      font-size: 0.92rem !important;
      line-height: 2em !important;
      padding: 4px 0 3px;
      cursor: pointer;

      &:hover,
      &.react-select__option--is-focused {
        color: rgba($white, 1);
      }

      &.react-select__option--is-selected {
        color: $purple;
        text-shadow: 0 0 4px rgba($purple, 0.7);

        &::after {
          width: 6px;
          height: 11px;
          border-bottom: 2px solid $purple;
          border-right: 2px solid $purple;
          margin-left: -15px;
          margin-top: 5px;
        }

        &:not(.grouped-option)::before {
          opacity: 0;
        }
      }

      &:not(.grouped-option)::before {
        opacity: 1;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 0;
        padding: 4px;
        margin-top: 8px;
        margin-left: -17px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba($white, 0.2);
      }
    }
  }

  .react-select__control--menu-is-open {
    .react-select__dropdown-indicator { transform: rotate(-180deg); }
  }

  .react-select__control--is-focused {
    outline: 0;
    box-shadow: none;
  }
}

.marketplace-filters--lvl1 {
  @include breakpoint(until-landscape) {
    .col {
      flex-basis: unset;
      width: 100%;
      text-align: center;

      &.col-align-center {
        margin-top: 15px;
        margin-bottom: 40px;
        order: 1;
      }

      &.col-align-right {
        flex: 0 0 50%;
        max-width: 50%;
        order: 3;
        position: relative;
        padding-top: 17px;
      }

      &:not(.col-align-center):not(.col-align-right) {
        flex: 0 0 50%;
        max-width: 50%;
        order: 2;
      }
    }

    .filters-clear-btn {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @include breakpoint(until-portrait) {
    .col {
      flex-basis: unset;
      width: 100%;
      text-align: center;

      &.col-align-center {
        margin-top: 15px;
        margin-bottom: 20px;
        order: 2;
      }

      &.col-align-right {
        flex: 0 0 100%;
        max-width: 100%;
        order: 3;
        position: relative;
        padding-top: 0;
        margin-bottom: 10px;
      }

      &:not(.col.col-align-center):not(.col.col-align-right) {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1;
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }

    .vsb-main { max-width: 210px; }
  }
}

.form-element-container--pricerange label {
  @include breakpoint(until-landscape) {
    text-align: center;
  }
}

.rarity-checkbox {
  label {
    &::before {
      content: '';
      width: 15px;
      height: 15px;
      display: inline-block;
      position: relative;
      top: 1px;
      margin-right: 4px;
      margin-left: -1px;
      background: 50% 50% no-repeat;
      background-size: contain;
    }
  }

  &.rarity-checkbox--common label::before {
    background-image: url('./assets/ico-rarity-common-white.svg');
  }

  &.rarity-checkbox--common .checkbox.checked {
    background-color: $green !important;
    border-color: $green !important;
  }

  &.rarity-checkbox--common.checkbox-is-checked label {
    color: $green !important;
    &::before { background-image: url('./assets/ico-rarity-common.svg'); }
  }

  &.rarity-checkbox--special label::before {
    background-image: url('./assets/ico-rarity-special-white.svg');
  }

  &.rarity-checkbox--special .checkbox.checked {
    background-color: #21a0fb !important;
    border-color: #21a0fb !important;
  }

  &.rarity-checkbox--special.checkbox-is-checked label {
    color: #21a0fb !important;
    &::before { background-image: url('./assets/ico-rarity-special.svg'); }
  }

  &.rarity-checkbox--epic label::before {
    background-image: url('./assets/ico-rarity-epic-white.svg');
  }

  &.rarity-checkbox--epic .checkbox.checked {
    background-color: $purple !important;
    border-color: $purple !important;
  }

  &.rarity-checkbox--epic.checkbox-is-checked label {
    color: $purple !important;
    &::before { background-image: url('./assets/ico-rarity-epic.svg'); }
  }

  &.rarity-checkbox--legendary label::before {
    background-image: url('./assets/ico-rarity-legendary-white.svg');
  }

  &.rarity-checkbox--legendary .checkbox.checked {
    background-color: #fac75e !important;
    border-color: #fac75e !important;
  }

  &.rarity-checkbox--legendary.checkbox-is-checked label {
    color: #fac75e !important;
    &::before { background-image: url('./assets/ico-rarity-legendary.svg'); }
  }

  &.rarity-checkbox--unique label::before {
    background-image: url('./assets/ico-rarity-unique-white.svg');
  }

  &.rarity-checkbox--unique .checkbox.checked {
    background-color: #dc3c2d !important;
    border-color: #dc3c2d !important;
  }

  &.rarity-checkbox--unique.checkbox-is-checked label {
    color: #dc3c2d !important;
    &::before { background-image: url('./assets/ico-rarity-unique.svg'); }
  }
}

.marketplace-filters--lvl2 {
  padding-top: 50px;
  padding-bottom: 60px;
  position: relative;

  @include breakpoint(until-landscape) {
    padding-bottom: 20px;
  }

  &::after {
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    background: rgba($white, 0.2);
    position: absolute;
    bottom: 0;
    left: 15px;
  }

  .row-title h5 {
    font-size: 0.9375rem;
    text-align: center;
  }

  .row {
    margin-bottom: 40px;

    @include breakpoint(until-landscape) {
      margin-bottom: 0;

      &:last-of-type {
        margin: 40px auto 0;
      }

      .col {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }

    &:last-of-type {
      max-width: 90%;
      margin: 60px auto 0;

      @include breakpoint(until-desktop) {
        max-width: 100%;
      }
    }
  }

  .row-filters-lvl2-2 label {
    margin-bottom: 15px;
  }
}

.row-filters-lvl2-2 {
  @include breakpoint(until-landscape) {
    .col,
    .col-3,
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .col-3 {
      margin-bottom: 80px;
    }
  }
}

.form-element-container--checkboxes {
  @include clearfix();

  padding-left: 100px;

  @include breakpoint(until-landscape) {
    padding-left: 0;
    text-align: center;
  }

  .form-element-container--checkbox {
    display: block;
    float: left;
    margin-bottom: 15px;

    @include breakpoint(until-landscape) {
      margin-bottom: 25px;
      float: none;
      display: inline-block;
    }

    @include breakpoint(until-portrait) {
      width: 50%;
      text-align: left;

      &:last-of-type {
        width: 57%;
      }
    }

    &:not(:last-child) { margin-right: 20px; }

    &.checkbox-is-checked {
      label.lbl-inline {
        color: $purple;
        text-shadow: 0 0 4px rgba($purple, 0.7);
      }
    }
  }

  .checkbox {
    display: block;
    float: left;
  }
}
