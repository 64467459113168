@import 'scss/init';

.pg-about-us {
  #main-content {
    background-size: 100% 100%;
  }

  p {
    line-height: 1.4em;
    margin-bottom: 30px;
  }
}

.about--hero {
  width: 100%;
  display: block;
  box-shadow: 0 50px 84px rgba(0, 0, 0, 0.3);
  margin-bottom: 50px;
}

.about--intro-content {
  max-width: 80%;
  margin-left: 5%;
  margin-top: 30px;

  @include breakpoint(until-portrait) {
    max-width: 98%;
    margin-left: 0;
  }
}

.about--section-team,
.about--section-advisors {
  margin-top: 150px;
}

.about--team {
  .about--team-item {
    padding: 0 20px;
    margin-bottom: 70px;
    text-align: center;
  }

  .about--team-item-avatar {
    max-width: 220px;
    display: block;
    margin: 0 auto 20px;
    border-radius: 220px;
    overflow: hidden;
  }

  a.about--team-item-avatar {
    position: relative;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(141.32deg, rgba(48, 224, 111, 0.8) 4.58%, rgba(17, 129, 57, 0.8) 122.96%);
      opacity: 0;
      transition: all 0.2s;
    }

    &::after {
      z-index: 20;
      background: url('../../containers/UpperHeader/assets/ico-social-linkedin.svg') 50% 50% no-repeat;
      background-size: 42px auto;
    }

    &:hover {
      &::before,
      &::after { opacity: 1; }
    }
  }

  .about--team-item-avatar,
  .about--team-item-avatar img {
    width: 100%;
    height: auto;
  }

  .about--team-item-title {
    margin-bottom: 3px;
  }

  .about--team-item-desc {
    max-width: 190px;
    margin: 0 auto;
    font-weight: 300;
  }
}

.about--section-advisors {
  .about--team {
    max-width: 975px;

    @include breakpoint(until-portrait) {
      max-width: 540px;
    }

    .about--team-item-title {
      color: $purple;
    }

    a.about--team-item-avatar::before {
      background:
        linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1),
          rgba(0, 0, 0, 0.1)
        ),
        linear-gradient(119.91deg, rgba(231, 106, 247, 0.8) -18.1%, rgba(160, 52, 174, 0.8) 82.06%);
    }
  }

  .col-md-4 {
    @include breakpoint(until-portrait) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
