@import 'scss/init';

.pg-contacts {

  #main-content { background-size: 100% 100%; }

  .contacts--adress { margin-bottom: 50px; }

  .contacts--form {
    margin-top: 50px;
    padding-bottom: 100px;

    .vsb-main {
      margin-top: 5px;

      .title { color: rgba($white, 0.8); }

      .vsb-menu li {
        text-transform: none;
        letter-spacing: 0;
      }
    }

    .btn {
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .form--submit--wrapper {
      display: flex;
      align-items: center;
    }

    .loading--spinner {
      margin-left: 10px;
    }
  }

  .selectbox {
    max-width: none;

    .react-select__option,
    .react-select__value-container {
      letter-spacing: normal;
      font-size: 1em;
      font-weight: 400;
      text-transform: none;
    }

    .react-select__placeholder {
      color: rgba($white, 0.5);
    }
  }

  #recaptcha-container {
    text-align: right;

    @include breakpoint(until-landscape) {
      margin-top: 20px;
      text-align: left;
    }

    .grecaptcha-badge {
      display: inline-block;
    }
  }
}
