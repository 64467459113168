@import 'scss/init';

.tabs-nav {
  position: relative;
  max-width: 1300px;
  margin: 0 auto 40px;

  nav,
  ul {
    @include clearfix();

    width: 100%;
    text-align: center;

    button {
      font-size: 1.06rem;
      line-height: 1em;
      color: rgba($white, 0.5);
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-weight: 500;
      position: relative;
      padding: 0 0 10px;
      background: none;
      border: 0;
      cursor: pointer;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: none;
        opacity: 0;
        transition: all 0.25s;
      }

      &:hover {
        color: rgba($white, 0.8);
      }
    }

    li {
      display: inline-block;
      &:not(:last-child) { margin-right: 40px; }

      &.active-tab button {
        color: rgba($white, 1);

        &::after {
          box-shadow: 3px 7px 15px rgba(13, 21, 62, 0.4);
          opacity: 1;
        }
      }
    }
  }

  &::after {
    content: '';
    width: 100%;
    height: 50px;
    background: url('./assets/line-shadow.png') 50% 50% no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: -30px;
    left: 0;
    pointer-events: none;
    opacity: 0.3;
    margin-top: 10px;
    border-top: 1px solid rgba($white, 0.5);
    transform: scaleY(-1);
  }
}
