@import 'scss/init';

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOutToNone {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }

  100% {
    display: none;
    opacity: 0;
  }
}

.collectible {
  width: 90%;
  text-align: center;
  position: relative;
  margin-bottom: 110px;

  @include breakpoint(until-portrait) {
    width: 100%;
    margin-bottom: 80px;
  }
}

.collectible--img {
  @include no-select();

  width: 95%;
  display: inline-block;
  mix-blend-mode: screen;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1) !important;

  @include breakpoint(until-portrait) {
    width: 90%;
  }

  .collectible & {
    transform: scale(1.6);
    transform-origin: 50% 50%;
  }

  .collectible:not(.status--static-item):hover & {
    transform: scale(1.95);
    transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  }

  img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }

  .collectible--img-static {
    width: 85%;
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: all 0s;
    margin: 0 auto;

    .collectible:hover & {
      opacity: 0;
      transition: all 0.25s;
      transition-delay: 0.5s;
    }
  }

  .collectible--video-hover {
    width: 85%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 20;
    display: none;
    transform: translateX(-50%);

    .collectible:hover & {
      display: block;
      animation: fadeInFromNone 0.25s forwards;
    }

    video {
      width: 100%;
      display: block;
    }
  }
}

.collectible--anchor {
  width: 100%;
  height: 50%;
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  z-index: 20;
  background: none;
  border: none;
  padding: 0;

  &::after {
    display: none;
  }
}

.collectible--details {
  text-align: left;
  margin-top: 30px;
  width: 100%;

  @include breakpoint(until-landscape) {
    .modal-window & {
      max-width: none;
      margin-top: 20px;
      left: 0;
    }
  }

  .cd-badge {
    margin-bottom: 15px;

    img {
      width: auto;
      height: 20px;
      display: block;

      @include breakpoint(until-portrait) {
        height: 16px;
      }
    }
  }

  .cd-h1 {
    font-size: 1.88rem;
    line-height: 1em;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    margin-bottom: 4px;

    @include breakpoint(until-desktop) {
      font-size: 1.68rem;
    }

    @include breakpoint(until-portrait) {
      font-size: 1.18rem;
      line-height: 1.15em;
    }

    a,
    a:hover {
      color: $white;
    }
  }

  .cd-h2,
  .cd-h3,
  .cd-h4,
  .cd-quantity {
    margin-bottom: 8px;
    font-size: 0.75rem;
    line-height: 1em;
    text-transform: uppercase;
    color: rgba($white, 0.7);
    font-weight: 500;
    letter-spacing: 0.15em;

    @include breakpoint(until-portrait) {
      font-size: 0.6rem;
    }

    strong {
      color: rgba($white, 1);
      font-weight: 500;
    }
  }

  .cd-h2 {
    width: 95%;
    line-height: 1.3em;
  }

  .cd-h3,
  .cd-quantity {
    color: $green;
    margin-bottom: 15px;
  }

  .cd-h4 {
    font-weight: 300;
    margin-top: -10px;
    margin-bottom: 15px;
  }

  .cd-price--fevr {
    font-size: 1.88rem;
    line-height: 1em;
    letter-spacing: 0.02em;
    color: $white;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1px;

    @include breakpoint(until-portrait) {
      font-size: 1.4rem;
    }

    small {
      font-size: 1.2rem;
      line-height: 1em;
      font-weight: 500;
      position: relative;
      top: -7px;
      margin-left: 3px;

      @include breakpoint(until-portrait) {
        font-size: 0.7rem;
      }
    }
  }

  .cd-price--exchange {
    font-size: 0.9375rem;
    line-height: 1em;
    letter-spacing: 0.02em;
    color: rgba($white, 0.8);
    font-weight: 400;
    text-transform: uppercase;

    small {
      font-size: 0.45rem;
      line-height: 1em;
      font-weight: 500;
      position: relative;
      top: -6px;
      margin-left: 3px;
    }
  }

  .cd-quantity {
    margin-top: 20px;
    margin-bottom: -5px;
  }

  .btn {
    min-width: 100px;
    margin-top: 20px;

    @include breakpoint(until-landscape) {
      padding: 16px 35px;
      font-size: 0.9rem;
    }

    @include breakpoint(until-portrait) {
      padding: 15px 20px;
      font-size: 0.75rem;
    }
  }

  .ico-info {
    margin-left: 5px;
    position: relative;
    top: 12px;
  }
}

.status--new {
  img.badge--new {
    width: 43px;
    height: auto;
    position: absolute;
    top: -8%;
    left: 0;
    z-index: 50;
    pointer-events: none;

    @include breakpoint(until-portrait) {
      top: -11%;
    }
  }
}

.cd-warning {
  color: rgba($red, 0.7);
  margin-top: 15px;
}
