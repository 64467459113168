@import 'scss/init';

.pg-packs {
  #main-content {
    background-size: 100% 600px;

    @include breakpoint(until-portrait) {
      background-size: 100% 350px;
    }
  }
}

.packs-hero {
  max-width: 900px;
  margin: 0 auto 130px;

  @include breakpoint(until-portrait) {
    margin-bottom: 70px;
  }
}

.packs-hero--title {
  width: 100%;
  display: block;
  text-align: center;
  text-shadow: 0 74px 60px $blue_dark, 0 34px 110px $blue_dark, 0 34px 110px rgba($blue_dark, 0.5);
  margin-bottom: -40px;
  position: relative;
  z-index: 20;

  @include breakpoint(until-portrait) {
    margin-bottom: 0;
  }

  .packs-hero--title-lvl1 {
    font-size: 1.75rem;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 18px;

    @include breakpoint(until-portrait) {
      font-size: 1.3rem;
      margin-bottom: 8px;
    }
  }

  .packs-hero--title-lvl2 {
    font-size: 4rem;
    line-height: 1em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-weight: 700;

    @include breakpoint(until-portrait) {
      font-size: 2.5rem;
    }
  }
}

.packs-hero--img {
  position: relative;
  z-index: 10;
}

.packs-hero--img,
.packs-hero--img img {
  width: 100%;
  display: block;
  mix-blend-mode: screen;
}

.packs--desc {
  position: relative;
  padding: 100px 0;
  margin: 50px 0 100px;

  @include breakpoint(until-portrait) {
    padding: 0;
  }

  .col,
  .col-md-6 {
    position: static;
  }

  .packs--img-desc-illustration {
    width: 35vw;
    position: absolute;
    top: 80px;
    right: 0;

    @include breakpoint(until-portrait) {
      display: none;
    }
  }
}
