@import 'scss/init';

.pg-home {
  #main-content {
    padding-top: 0;
    overflow: hidden;
    background-size: 100% 65%;
  }
}

.section--home-hero {
  position: relative;
  z-index: 10;
  padding-top: 250px;
  padding-bottom: 400px;
  background: url('./assets/hero-bg.jpg') 0 0 no-repeat;
  background-size: 100% 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%);

  @include breakpoint(until-landscape) {
    padding-top: 100px;
    padding-bottom: 260px;

    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .home-hero--col-1 {
      margin-bottom: 180px;
    }

    .home-hero--img.home-hero--img-4 {
      top: -20%;
      left: -7%;
    }
  }

  @include breakpoint(until-portrait) {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 95%);
    padding-bottom: 50px;
  }

  .home-hero--title {
    margin-top: 30px;
    font-size: 4.3rem;
    line-height: 0.9em;
    text-shadow: 0 10px 20px rgba(29, 53, 117, 0.6), 0 10px 20px rgba(29, 53, 117, 0.6);

    @include breakpoint(until-landscape) {
      font-size: 4rem;
    }

    @include breakpoint(until-portrait) {
      font-size: 2.6rem;
      margin-top: 0;
    }

    .hero-title--line { display: block; }

    .hero-title--line-1 {
      font-size: 2.5rem;
      line-height: 0.95em;
      display: inline-block;
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0.1em;
      margin-bottom: 20px;

      @include breakpoint(until-portrait) {
        font-size: 1.5rem;
        margin-bottom: 10px;
      }
    }

    .hero-title--line-2 {
      color: $green;
    }

    .hero-title--line-4 {
      color: $purple;
    }
  }

  .home-hero--desc {
    max-width: 520px;
    font-size: 1.25rem;
    line-height: 1.25em;
    margin-top: 15px;

    @include breakpoint(until-portrait) {
      font-size: 1.15rem;
    }
  }

  .btn {
    padding-top: 26px;
    padding-bottom: 26px;
    margin-top: 10px;
    box-shadow: 0 25px 50px rgba(29, 53, 117, 0.5);

    @include breakpoint(until-portrait) {
      width: 100%;
    }
  }

  .home-hero--img-wrapper {
    @include no-select();

    width: 130%;
    position: relative;
    top: -80px;
    pointer-events: none;

    @include breakpoint(until-landscape) {
      width: 100%;
      left: -10%;
    }

    @include breakpoint(until-portrait) {
      width: 130%;
      left: -14%;
      top: -120px;
    }
  }

  .home-hero--img-1 {
    width: 100%;
    display: block;
    position: relative;
    z-index: 10;
  }

  .home-hero--img-2,
  .home-hero--img-3,
  .home-hero--img-4 {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
  }

  .home-hero--img-3 {
    z-index: 30;
    mix-blend-mode: screen;
  }

  .home-hero--img-4 {
    z-index: 40;

    @include breakpoint(until-portrait) {
      top: -10%;
    }
  }

  .btn-get-involved {
    margin-left: 24px;

    @include breakpoint(until-portrait) {
      margin-left: 0;
    }
  }
}

.section--video-how-to {
  margin-top: -400px;
  margin-bottom: 150px;
  position: relative;
  z-index: 20;

  @include breakpoint(until-desktop) {
    margin-top: -350px;
  }

  @include breakpoint(until-portrait) {
    margin-top: -100px;
    margin-bottom: 80px;

    .video-sound-control--btn {
      bottom: 20px;
      left: 27px;
    }
  }

  .video--how-to {
    cursor: pointer;
    box-shadow: -40px 60px 94px rgba(0, 0, 0, 0.2);

    @include breakpoint(until-desktop) {
      .col-md-10 {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .offset-md-1 {
        margin-left: 0;
      }

      .video--iframe-container {
        position: relative;
        width: 100%;
        padding-bottom: 52.94%;
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.section--marketplace-cta {
  .col-md-6 {
    @include breakpoint(until-landscape) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .marketplace-cta--item {
    .title-lines {
      position: relative;
      z-index: 20;
      margin-top: -60px;
      transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);

      small {
        display: block;
        font-size: 1.5rem;
        margin-bottom: -9px;

        @include breakpoint(until-portrait) {
          font-size: 1.35rem;
          margin-bottom: -5px;
        }
      }
    }

    .marketplace-cta--item-img {
      mix-blend-mode: screen;
      transform-origin: 50% 50%;
      transition: all 0.4s cubic-bezier(0.16, 1, 0.3, 1);
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 350px;
      display: block;
      margin: 0 auto;
      position: relative;
      z-index: 10;

      @include breakpoint(until-desktop) {
        max-height: 250px;
      }

      @include breakpoint(until-landscape) {
        max-height: 300px;
      }
    }

    &:hover {
      .marketplace-cta--item-img { transform: scale(1.1); }
      .title-lines { transform: translateY(-8px); }
    }
  }
}

.section--embassador {
  max-width: 1100px;
  margin: 150px auto 220px;

  .row {
    align-items: center;
  }

  .embassador--text {
    width: 150%;

    @include breakpoint(until-portrait) {
      width: 100%;
    }

    .embassador--title {
      margin-bottom: 25px;

      @include breakpoint(until-portrait) {
        font-size: 1.8rem;
      }
    }

    p {
      width: 90%;
      margin-bottom: 15px;

      @include breakpoint(until-portrait) {
        width: 100%;
      }
    }
  }

  .embassador--col2 {
    justify-content: right;
    text-align: right;
  }

  .embassador--img {
    width: 95%;
    display: inline-block;
    mix-blend-mode: screen;
    transform: translateX(60px);
    pointer-events: none;

    @include breakpoint(until-portrait) {
      width: 100%;
      transform: none;
    }
  }
}
