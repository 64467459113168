@import 'scss/init';

.pack {
  width: 90%;
  text-align: center;
  margin-bottom: 65px;
  position: relative;

  @include breakpoint(until-portrait) {
    width: 100%;
    margin-bottom: 80px;
  }

  .pack--img {
    width: 90%;
    transform: scale(1);
    position: relative;
    mix-blend-mode: screen;

    @include breakpoint(until-portrait) {
      width: 90%;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      background: url('../assets/light-flare.jpg') 50% 50% no-repeat;
      background-size: 100% auto;
      position: absolute;
      top: -8%;
      right: -43%;
      z-index: 20;
      mix-blend-mode: screen;
      transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    }

    img {
      width: 100%;
      display: block;
    }

    .pack--img-item {
      width: 100%;
      display: block;
      position: relative;
      z-index: 20;
      transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

      img {
        position: relative;
        z-index: 10;
      }
    }

    .pack--img-shape {
      width: 100%;
      height: auto;
      display: block;
      position: absolute;
      top: -5%;
      left: 12%;
      z-index: 10;
      transition: all 0.1s linear;
      opacity: 0.5;
    }
  }

  .pack--anchor {
    display: block;
    text-align: left;
  }

  .pack--details .cd-h1 { width: 98%; }

  .collectible--details,
  .pack--details {
    text-align: left;
    margin-top: 30px;

    .cd-badge {
      margin-bottom: 15px;

      img {
        width: auto;
        height: 20px;
        display: block;

        @include breakpoint(until-portrait) {
          height: 16px;
        }
      }
    }

    .cd-h1 {
      font-size: 1.88rem;
      line-height: 1em;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      margin-bottom: 4px;

      @include breakpoint(until-desktop) {
        font-size: 1.68rem;
      }

      @include breakpoint(until-portrait) {
        font-size: 1.18rem;
        line-height: 1.15em;
      }

      a,
      a:hover {
        color: $white;
      }
    }

    .cd-h2,
    .cd-h3 {
      margin-bottom: 8px;
      font-size: 0.75rem;
      line-height: 1em;
      text-transform: uppercase;
      color: rgba($white, 0.7);
      font-weight: 500;
      letter-spacing: 0.15em;

      @include breakpoint(until-portrait) {
        font-size: 0.6rem;
      }

      strong {
        color: rgba($white, 1);
        font-weight: 500;
      }
    }

    .cd-h2 {
      width: 95%;
      line-height: 1.3em;
    }

    .cd-h3 {
      color: $green;
      margin-bottom: 15px;
    }

    .cd-quantity {
      @include breakpoint(until-portrait) {
        font-size: 0.6rem;
      }
    }

    .cd-price--fevr {
      font-size: 1.88rem;
      line-height: 1em;
      letter-spacing: 0.02em;
      color: $white;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1px;

      @include breakpoint(until-portrait) {
        font-size: 1.4rem;
      }

      small {
        font-size: 1.2rem;
        line-height: 1em;
        font-weight: 500;
        position: relative;
        top: -7px;
        margin-left: 3px;

        @include breakpoint(until-portrait) {
          font-size: 0.7rem;
        }
      }
    }

    .cd-price--exchange {
      font-size: 0.9375rem;
      line-height: 1em;
      letter-spacing: 0.02em;
      color: rgba($white, 0.8);
      font-weight: 400;
      text-transform: uppercase;

      small {
        font-size: 0.45rem;
        line-height: 1em;
        font-weight: 500;
        position: relative;
        top: -6px;
        margin-left: 3px;
      }
    }

    .has-submenu--list {
      button {
        font-size: 0.9rem;
      }
    }

    .btn {
      min-width: 100px;
      margin-top: 20px;

      @include breakpoint(until-landscape) {
        padding: 16px 35px;
        font-size: 0.9rem;
      }

      @include breakpoint(until-portrait) {
        padding: 15px 20px;
        font-size: 0.75rem;
      }
    }
  }

  &.status--sold_out {
    @include no-select();

    .pack--img-shape {
      opacity: 0;
    }

    img.badge--sold-out {
      width: 112%;
      height: auto;
      position: absolute;
      top: 50%;
      left: -3%;
      z-index: 100;
      transform: rotate(-11deg) translateY(-50%);
      box-shadow: 0 10px 30px rgba($blue_dark, 1);
    }

    .pack--details {
      opacity: 0.65;
    }

    .cd-cta {
      opacity: 0.3;
    }

    .pack--img-item,
    .pack--img::after {
      opacity: 0.65;
    }
  }

  &:not(.status--static-item):hover {
    .pack--img {
      transform: scale(1);

      .pack--img-item {
        transform: scale(1.05);
      }

      .pack--img-shape {
        transform: scale(1.1) rotate(180deg);
        opacity: 1;
        transition-duration: 14s;
      }
      &::after { right: -45.5%; }
    }
  }
}
