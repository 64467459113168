@import 'scss/init';

.pg-fevr {
  #main-content {
    background-size: 100% 1600px;
  }

  p {
    line-height: 1.4em;
  }
}

.fevr--tokens-hero {
  width: 100%;
  display: block;

  @include breakpoint(until-landscape) {
    max-width: 200px;
    margin: 0 auto;
  }
}

.fevr--hero-content {
  max-width: 80%;
  margin-left: 5%;

  @include breakpoint(until-landscape) {
    max-width: 100%;
    margin-left: 0;
  }

  .container-cta-btns {
    padding: 0;
    margin-top: 30px;

    li {
      display: inline-block;

      @include breakpoint(until-portrait) {
        display: block;
        float: none;
      }
    }

    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      padding-left: 25px !important;
      padding-right: 25px !important;

      @include breakpoint(until-portrait) {
        margin-bottom: 10px;
      }
    }
  }
}

.fevr--hero-title {
  padding: 50px 0 30px;
  background: url('./assets/fevr-title-lines.svg') 50% 50% no-repeat;
  background-size: auto 100%;
  margin-bottom: 20px;
}

.fevr--section-1 {
  @include breakpoint(until-landscape) {
    .col-md-5,
    .col-md-7 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.fevr--section-2 { margin-top: 225px; }
.fevr--img-ecosystem { mix-blend-mode: screen; }

.row--fevr-infographic {
  margin-top: 70px;

  @include breakpoint(until-landscape) {
    .col-md-2 {
      margin-bottom: 20px;
      text-align: center;
    }

    .col-md-2,
    .col-md-10 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.fevr--section-3 {
  margin-top: 200px;

  .title-lines {
    margin-bottom: 30px;
  }
}

.fevr--business-model-desc {
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
}

.fevr--business-model-item {
  @include breakpoint(until-portrait) {
    text-align: center;
  }

  .fevr--business-model-icon {
    max-width: 150px;
    max-height: 85px;
    display: block;
    margin-bottom: 20px;

    @include breakpoint(until-portrait) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.fevr--business-model-icons {
  margin-top: 100px;

  .col-md-4 {
    @include breakpoint(until-landscape) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include breakpoint(until-portrait) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .fevr--business-model-item { margin-bottom: 70px; }
}

.fevr--section-4 {
  margin-top: 225px;

  .title-lines {
    margin-bottom: 30px;
  }
}

.fevr--tokenomics-desc {
  text-align: center;
  max-width: 650px;
  margin: 0 auto 50px;
}

.fevr--token-supply {
  margin-top: 40px;
  margin-bottom: 40px;

  img {
    width: 55px;
    display: inline-block;
    margin-right: 5px;
  }

  .fevr--token-supply-txt {
    display: inline-block;
    font-size: 1.25rem;
  }
}

.fevr--distribution-table {
  width: 85%;
  margin: 0 auto;
  display: block;
}

.fevr--img-full {
  width: 100%;
  margin: 0 auto;
  display: block;
}

.fevr--distribution-periodicity,
.fevr--utility {
  margin-top: 150px;
  .mid-title { margin-bottom: 30px; }
}

.fevr--utility .fevr--img-full { margin-top: 25px; }

.fevr--section-5 { margin-top: 200px; }

.fevr--section-2,
.fevr--section-3,
.fevr--section-4,
.fevr--section-5 {
  @include breakpoint(until-portrait) {
    margin-top: 120px;
  }
}
