html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
::after,
::before {
  box-sizing: inherit;
}

.row {
  --bs-gutter-x: 30px;

  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
