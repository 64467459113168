@import 'scss/init';

.bottom-area-title {
  text-align: center;
  margin-bottom: 45px;
}

.official-partners-list {
  width: 100%;
  text-align: center;
  max-width: 1200px;

  .col-partner {
    align-self: center;
    text-align: center;
    margin-bottom: 20px;

    @include breakpoint(until-portrait) {
      margin-bottom: 10px;
    }
  }

  .col-md-3 {
    @include breakpoint(until-landscape) {
      flex: 0 0 33.3333333333%;
      max-width: 33.3333333333%;
    }

    @include breakpoint(until-portrait) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  a {
    display: block;

    &:hover { opacity: 0.75; }
  }

  img {
    max-width: 200px;
    max-height: 85px;
    display: block;
    margin: 0 auto;

    @include breakpoint(until-desktop) {
      max-width: 90%;
      max-height: 60px;
    }

    @include breakpoint(until-landscape) {
      max-width: 100%;
    }

    @include breakpoint(until-portrait) {
      max-width: 90%;
      max-height: 60px;
    }
  }
}

#official-partners {
  margin-top: 180px;

  @include breakpoint(until-portrait) {
    margin-top: 80px;
  }

  .container {
    &,
    .row {
      @include breakpoint(until-landscape) {
        padding: 0;
      }
    }
  }

  img {
    max-width: 90%;
    max-height: 50px;
  }
}

#powered-by-binance {
  text-align: center;
  font-size: 1.05rem;
  line-height: 1em;
  font-weight: 300;
  color: rgba($white, 0.8);
  margin: 80px 0 110px;

  @include breakpoint(until-landscape) {
    font-size: 0.8rem;
    margin: 50px 0 60px;
  }

  img {
    width: 65px;
    height: auto;
    display: block;
    margin: 0 auto 20px;

    @include breakpoint(until-desktop) {
      width: 50px;
    }

    @include breakpoint(until-landscape) {
      width: 45px;
    }
  }
}

#main-footer {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background:
      radial-gradient(
        83.14% 83.14% at 50% 104.01%,
        rgba(48, 224, 111, 0.6) 0%,
        rgba(38, 175, 87, 0.46875) 21.88%,
        rgba(0, 0, 0, 0) 100%
      );
    opacity: 0.6;
    z-index: -1;
    pointer-events: none;

    @include breakpoint(until-portrait) {
      background:
        radial-gradient(
          463.14% 83.14% at 50% 104.01%,
          rgba(48, 224, 111, 0.6) 0%,
          rgba(38, 175, 87, 0.46875) 21.88%,
          rgba(0, 0, 0, 0) 100%
        );
    }
  }

  .footer-footnote {
    width: 100%;
    background: rgba($black, 0.35);
    border-top: 1px solid rgba($white, 0.2);
    padding-top: 35px;
    padding-bottom: 30px;
    font-size: 0.55rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 300;

    @include breakpoint(until-desktop) {
      letter-spacing: 0.05em;
    }

    @include breakpoint(until-landscape) {
      font-size: 0.55rem;

      .col {
        width: 100%;
        flex-basis: unset;
        text-align: center;

        &.col-r { margin-top: 20px; }

        img { width: 120px; }
      }
    }

    @include breakpoint(until-portrait) {
      padding-left: 0;
      padding-right: 0;
    }

    .footer-footnote--col-1 {
      text-align: left;

      @include breakpoint(until-landscape) {
        order: 3;
        margin-top: 15px;
        text-align: center;
      }
    }

    .footer-footnote--col-2 {
      text-align: center;

      @include breakpoint(until-landscape) {
        order: 1;
        margin-bottom: 10px;
      }
    }

    .footer-footnote--col-3 {
      text-align: right;

      @include breakpoint(until-landscape) {
        order: 2;
        text-align: center;
      }
    }

    .footer-logo {
      width: 150px;
      height: auto;
      display: inline-block;
      position: relative;
      top: -2px;
    }

    .footer-footnote--nav {
      li {
        display: inline-block;
        &:not(:last-child) { margin-right: 12px; }
      }

      a {
        color: $white;
        &:hover { color: $green; }
      }
    }

    .social-media-nav {
      margin-right: 0;
    }
  }
}
