@import 'scss/init';

$section-margin: 1.5em;

.cookies-banner {
  display: flex;
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  left: 0;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-height: 100%;
  padding: 1em 0;
  overflow-y: auto;
  background: rgba($black, 0.85);
  color: $color-text;
  transform: translateY(calc(100% + 1px));

  @include breakpoint(landscape) {
    flex-direction: row;
    padding: 0 $section-margin;
  }

  &.cookies-banner--show {
    transform: translateY(0);
    transition: transform 0.5s ease-out;
  }
}

.cookies-banner__content {
  flex: 1 1 auto;
  padding: 0 $section-margin;
  font-size: 0.85rem;

  @include breakpoint(landscape) {
    padding: $section-margin 0;
  }
}

.cookies-banner__links {
  margin-top: 0.35em;

  a {
    display: inline-block;
    color: $color-text;
    text-align: left;

    &:not(:first-child) {
      margin-left: 1em;
    }

    &:hover {
      color: $color-green;
    }
  }
}

.cookies-banner__btn-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  padding: 1em $section-margin 0;

  @include breakpoint(landscape) {
    flex: 1 0 auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1em 0 1em $section-margin;
  }

  .btn {
    width: 150px;
  }
}

.cookies-banner__btn--more {
  order: 2;
}

.cookies-banner__options {
  margin-top: 1rem;

  .form-element-container--checkbox {
    margin: 0.5em 0;
    display: flex;
    cursor: pointer;

    .checkbox {
      min-width: 28px;
      min-height: 28px;
    }

    label {
      display: inline-block;
      margin: 8px 0 0 0.75em;
      text-transform: none;
      font-size: 0.85rem;
      font-weight: 400;
      letter-spacing: normal;
      cursor: pointer;

      &::before {
        top: 0.325em;
        left: -2.25em;
        width: 1.75em;
        height: 1em;
        border-radius: 0.5em;
        cursor: pointer;
      }

      &::after {
        top: calc(0.325em + 2px);
        left: calc(-2.25em + 2px);
        width: calc(1em - 4px);
        height: calc(1em - 4px);
        border-radius: 0.5em;
        cursor: pointer;
      }
    }

    input {
      width: 1em;
      height: 1.325rem;
      cursor: pointer;

      &:checked ~ .custom-control-label::after {
        transform: translateX(0.75em);
      }
    }
  }
}
