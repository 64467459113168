@import 'scss/init';

// All the !importants are so these settings overcome normal page padding and background, without
// having to override each one individually.
.pg-mycollection--pack-open {
  #main-content {
    padding-top: 250px !important;
    background-size: 100% 100% !important;

    @include breakpoint(until-landscape) {
      padding-top: 120px !important;
    }

    @include breakpoint(until-portrait) {
      background-size: 100% 350px !important;
    }
  }

  .container--pack-video-open {
    max-width: 1000px;
  }

  .pack-open--container,
  .pack-open--container video {
    box-shadow: 0 50px 50px rgba(0, 0, 0, 0.35);
    padding: 0;
    margin: 0;
    width: 100%;
    object-fit: contain;
  }

  .pack-open--container video { pointer-events: none; }

  .pack-open--skip-btn {
    margin-top: 20px;

    .skip-arrow {
      position: relative;
      top: -1px;
      margin-left: 3px;
    }
  }

  &.pack-is-opened {
    .pack-open--overlay {
      pointer-events: all;
      opacity: 1;
    }
  }

  .row-skip-btn {
    padding-bottom: 50px;
  }
}
