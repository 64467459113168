@import 'scss/init';

.release-countdown {
  margin: 30px 0 20px;
  text-align: left;
  text-shadow: 0 10px 20px rgba(29, 53, 117, 0.8);

  @include breakpoint(until-portrait) {
    text-align: center;
  }

  &.rc-center {
    text-align: center;
  }

  .pg-packs & {
    margin-top: -50px;
    margin-bottom: 80px;
    position: relative;
    z-index: 100;
  }

  ul {
    @include clearfix();

    display: inline-block;

    li {
      min-width: 90px;
      display: block;
      float: left;
      text-align: center;
      position: relative;

      @include breakpoint(until-portrait) {
        min-width: 65px;
      }

      @include breakpoint(until-mobile) {
        min-width: 55px;
      }

      .release-countdown--seconds {
        text-align: left;
        transform: translateX(3px);
      }

      &:not(:last-child) {
        margin-right: 13px;

        @include breakpoint(until-portrait) {
          margin-right: 10px;
        }

        &::after {
          content: ':';
          height: auto;
          font-size: 1.563rem;
          line-height: 1em;
          font-weight: 500;
          position: absolute;
          top: 36%;
          right: 0;
          transform: translate(12px, -50%);
          opacity: 0.8;

          @include breakpoint(until-portrait) {
            transform: translate(8px, -50%);
          }

          @include breakpoint(until-mobile) {
            transform: translate(9px, -50%);
          }
        }
      }
    }
  }

  .release-countdown-nr {
    display: block;
    font-size: 4.3rem;
    line-height: 1em;
    letter-spacing: -0.035em;
    font-weight: 500;
    margin-bottom: -10px;

    @include breakpoint(until-portrait) {
      font-size: 3rem;
    }

    @include breakpoint(until-mobile) {
      font-size: 2.75rem;
    }
  }

  small {
    display: block;
    font-size: 0.625rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    opacity: 0.7;

    &.small-seconds {
      transform: translateX(-5px);
    }
  }

  h3.mid-title {
    @include breakpoint(until-portrait) {
      font-size: 1.25rem;
      text-align: center;
    }
  }
}
